import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

import macbookFrontImage from '../img/macbook-front.png';
import zaiperLogoImage from '../img/zapier-logo.png';
import Pricing from "../components/Pricing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck, faCloud, faExpand, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

// markup
const Index = (props) => {
  return (
    <main >
        <WebsiteLayout location={props.location} title="Home">
        <section className="masthead masthead-9 -inverse">
        <div className="container">
            <div className="row">

                <div className="call-to-action col-xs-12 text-center">
                    <h2 className="heading">Complete Platform for DJs, Artists and Event Companies <br/> <small>Have all your information right at your fingertips.</small></h2>
                    <a href="/features/"><button className="btn primary-btn -rounded -white" style={{marginTop: '1rem'}} >&nbsp;&nbsp;View Features <FontAwesomeIcon width="16" icon={faArrowRight} /></button></a> <a href="https://app.djmanager.co.uk/register"><button className="btn ghost-btn -transparent" style={{marginTop: '1rem'}}>14 Day Free Trial</button></a>
                    <div className="row md-pt-8">
                        <div className="col-sm-12">
                            <div className="heading" style={{marginBottom: 0}}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-1">&nbsp;</div>
                                    <div className="col-xs-12 col-sm-10">
                                        <div className="row text-center">  
                                            <div className="col-xs-12 col-sm-4">
                                                <p style={{color: 'white'}}>
                                                    <FontAwesomeIcon width="16" icon={faCheck} />
                                                    &nbsp;
                                                    Free Trial
                                                </p>
                                            </div>
                                            <div className="col-xs-12 col-sm-4">
                                                <p style={{color: 'white'}}>
                                                    <FontAwesomeIcon width="16" icon={faCheck} />
                                                    &nbsp;
                                                    No Credit-Card Required
                                                </p>
                                            </div>
                                            <div className="col-xs-12 col-sm-4">
                                                <p style={{color: 'white'}}>
                                                    <FontAwesomeIcon width="16" icon={faCheck} />
                                                    &nbsp;
                                                    Cloud Based
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-1">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="device col-xs-12">
                    <img src={macbookFrontImage} className="img-responsive" alt="" />
                </div>

            </div>
        </div>
    </section>

    <section className="feature feature-8">
        <div className="container">
            <div className="row">

                <div className="feature-block col-sm-4 col-md-4 col-lg-4">
                    <FontAwesomeIcon width="16" icon={faExpand} className="outline-icon" />
                    <h3 className="sub-heading">Always Developing</h3>
                    <p>DJ Manager is being actively developed, with new features added weekly.</p>
                </div>

                <div className="feature-block col-sm-4 col-md-4 col-lg-4">
                    <FontAwesomeIcon width="16" icon={faMobileAlt} className="outline-icon" />
                    <h3 className="sub-heading">Mobile Compatible</h3>
                    <p>We develop with mobile in mind, so you can access DJ Manager on any device, big or small.</p>
                </div>

                <div className="feature-block col-sm-4 col-md-4 col-lg-4">
                    <FontAwesomeIcon width="16" icon={faCloud} className="outline-icon" />
                    <h3 className="sub-heading">Cloud Based and Always Online</h3>
                    <p>DJ Manager is fully cloud-based, allowing you to access it from anywhere, anytime.</p>
                </div>

            </div>
        </div>
    </section>

    <section className="cta cta-1 -inverse">
        <div className="container">
            <div className="row">

                <div className="col-lg-10 col-lg-offset-1">
                    <h2 className="heading">Save yourself time, money, and <strong>improve your customer service.</strong><br /> <small>Have all the information you need right at your fingertips.</small></h2>
                    <a href="https://app.djmanager.co.uk/register"><button className="btn flat-btn -blue">Create Account</button></a>
                </div>

            </div>
        </div>
    </section>

    <section className="content content-3">
        <div className="container">

            <div className="row">

                <div className="desc col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <i className="outline-icon fa fa-angle-double-right"></i>
                    <h2 className="sub-heading">Intuitive User Interface</h2>

                    <p>With DJ Manager's dynamic links, you can access all the information you need in just a few clicks. Client rings you on the spot? Add their information to the system in seconds.</p>
                </div>

                <div className="image col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <img src={macbookFrontImage} className="img-responsive" alt="Quick and Easy to use Interface" />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <hr />
                </div>

                <div className="desc pull-right col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <i className="outline-icon fa fa-exchange"></i>
                    <h2 className="sub-heading">Fully Integrated Solution</h2>

                    <p>Why use multiple tools located in different places, when DJ Manager can bring all this into once place. We also offer connections with Zapier for further integration.</p>
                </div>

                <div className="image col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <img src={zaiperLogoImage} className="img-responsive" alt="" style={{width:'50%'}} />
                </div>

            </div>
        </div>
    </section>

    <section className="pricing pricing-2">
        <Pricing />
    </section>
{/* 
    <section className="testimonial testimonial-1" style="display:none;">
        <div className="container">
            <div className="row js-flickity">

                <div className="col-xs-12 gallery-cell">
                    <blockquote>
                        <p>This is a solid Bootstrap theme, there's no doubt about that. The minimalistic touches are a perfect way to kickstart a lot of your projects! Add that to the fact that this theme will update bi-weekly makes it an insta-buy.</p>
                        <footer><img src="" alt="" className="img-responsive ui-face"><p>Someone famous<br/><cite title="Source Title">Badass company</cite></p></footer>
                    </blockquote>
                </div>

            </div>
        </div>
    </section> */}
        </WebsiteLayout>
    </main>
  )
}

export default Index;